import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import HeroImage from '../svg/HeroImage';
import DataCharts0 from '../svg/undraw_all_the_data_re_hh4w.svg';
import DataCharts1 from '../svg/undraw_predictive_analytics_kf-9-n.svg';
import DataCharts2 from '../svg/undraw_team_collaboration_re_ow29.svg';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"; //highlight-line
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import Seo from 'gatsby-plugin-wpgraphql-seo';
import Seo from "../components/Seo";




export default function Home({ data }) {
  //highlight-line
  return (
    <Layout key="home" >
      
      {data.allWpPage.nodes.map((node, index) => (
      <Seo key={index + Math.random()} title={node.seo.title} description={node.seo.metaDesc} />
      ))}

      {/* start the home page data */}


      <section id="home" className="pt-10 lg:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2 lg:pr-20">

            {data.allWpPage.nodes.map((node, index) => (

              <div key={index + Math.random()}>
                <h1 key={index + Math.random()} className="text-center lg:text-left text-4xl lg:text-5xl xl:text-6xl font-bold leading-none"> {node.title}</h1>
                <div key={index + Math.random()} className="text-justify lg:text-left lg:text-xl mt-6 font-light" dangerouslySetInnerHTML={{ __html: node.content }}></div>
              </div>

            ))}
            <p className="mt-8 md:mt-12">
              <AnchorLink stripHash to="/#contact">  <Button size="lg">Get in touch!</Button></AnchorLink>
            </p>

          </div>
          <div className="pt-10 lg:pt-0 lg:w-1/2 w-full h-full">
            <HeroImage />
          </div>
        </div>
      </section>

      <section className="container mx-auto pt-20" ><hr></hr></section>

      {/* start the Work data */}
      <section id="work" className="pt-10 lg:pt-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl lg:text-5xl font-semibold">Work</h2>
        </div>

      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            {data.allWpPage.nodes.map((node, index) => (
              <div key={index + Math.random()} >
                <h3 key={node.homePage.section1Title} className="text-2xl text-center lg:text-left lg:text-3xl font-semibold leading-tight">{node.homePage.section1Title}</h3>
                <p key={index + Math.random()} className="mt-8 text-lg text-justify lg:text-xl font-light leading-relaxed">
                  {node.homePage.section1Text}
                </p>
              </div>
            ))}
          </div>

        }
        secondarySlot={<DataCharts0 className="pt-10 lg:pt-0 w-full h-full" />}
      />


      <SplitSection
        reverseOrder
        primarySlot={
          <div className="pt-20 lg:pl-32 xl:pl-48">
            {data.allWpPage.nodes.map((node, index) => (
              <div key={index + Math.random()} >
                <h3 key={node.homePage.section2Title} className="text-2xl text-center lg:text-left lg:text-3xl font-semibold leading-tight">{node.homePage.section2Title}</h3>
                <p key={index + Math.random()} className="mt-8 text-lg text-justify lg:text-xl font-light leading-relaxed">
                  {node.homePage.section2Text}
                </p>
              </div>
            ))}
          </div>

        }
        secondarySlot={<DataCharts1 className="pt-10 lg:pt-0 w-full h-full" />}
      />



      <SplitSection
        primarySlot={

          <div className="pt-20 lg:pr-32 xl:pr-48">
            {data.allWpPage.nodes.map((node, index) => (
              <div key={index + Math.random()} >
                <h3 key={node.homePage.section3Title} className="text-2xl text-center lg:text-left lg:text-3xl font-semibold leading-tight">{node.homePage.section3Title}</h3>
                <p key={index + Math.random()} className="mt-8 text-lg text-justify lg:text-xl font-light leading-relaxed">
                  {node.homePage.section3Text}
                </p>
              </div>
            ))}
          </div>

        }
        secondarySlot={<DataCharts2 className="pt-10 lg:pt-0 w-full h-full" />}
      />

</section>


      {/* start the Skills data */}
      <section id="skills" className="pt-32 pb-5">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl lg:text-5xl font-semibold">Skills</h2>
        </div>
      </section>

      <section className="pb-20">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">Platforms, Tools, and Code</LabelText>
          <div className="grid gap-5 lg:grid-cols-3  px-3 sm:flex-row sm:grid-cols-1 mt-8 lg:px-24">
            {data.allWpSkill.nodes.map((node, index) => (
              <Card key={index + Math.random()} className="col-span-1">
                <GatsbyImage key={index + Math.random()} image={getImage(node.featuredImage.node.localFile)} alt={node.title} />
                <p className="text-2xl lg:text-3xl font-semibold text-primary pt-3">{node.title}</p>
                <div key={index + Math.random()} className="mt-4" dangerouslySetInnerHTML={{ __html: node.content }}></div>
              </Card>
            ))}

          </div>
        </div>
      </section>



      {/* start the Clients data */}

      <section id="clients" className="pt-36">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl lg:text-5xl font-semibold">Clients</h2>
        </div>
      </section>


      <section className="pb-20">
        <div className="container mx-auto text-center">
          <div className="grid gap-2 xl:grid-cols-5 md:grid-cols-2 mt-12">
            {data.allWpClient.nodes.map((node, index) => (
              <div key={index + Math.random()} className="p-3 grid grid-col-5">
                <Card key={index + Math.random()} className="col-span-1">
                  <div key={index + Math.random()} className="flex justify-center items-center h-full">
                    <GatsbyImage key={index + Math.random()} image={getImage(node.featuredImage.node.localFile)} alt={node.title} />
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>


      {/* start the Blog data */}

      <section id="blog" className="py-40">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Blog</h2>
          <div className="grid lg:grid-cols-3 sm:flex-row sm:grid-cols-1 sm:-mx-3 mt-12">
            {data.allWpPost.nodes.map((node, index) => (
              <div key={index + Math.random()} className="py-5 flex-1 px-3">
                <div key={index + Math.random()}
                  className={`pb-10 rounded-lg border border-solid border-gray-200 mb-8 h-full
overflow-hidden shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-5 hover:shadow-2xl	 cursor-pointer m-auto`}
                  style={{ boxShadow: '0 10px 28px rgba(0,0,0,.08)' }}
                >

                  <Link to={node.uri}> 
                  {node.featuredImage && 
                   <GatsbyImage key={index + Math.random()} className="object-cover max-h-56 w-full" image={getImage(node.featuredImage.node.localFile)} alt={node.title} />
                  }
                   </Link>
                  <p className="font-semibold text-xl pt-5 ">
                    <Link to={node.uri}>{node.title}</Link>
                  </p>
                  <p className="pt-6">
                    <Link to={node.uri}> <Button size="sm">Read More</Button> </Link></p>
                </div>
              </div>
            ))}
          </div>
          <p className="pt-6"><Link to="/blog"> <Button size="xl">Load More...</Button> </Link></p>
        </div>
      </section>



      {/* start the Blog data */}

      <section id="contact" className="container mx-auto my-20 py-20 bg-gray-200 rounded-lg text-center">
        <h3 className="text-4xl lg:text-5xl font-semibold">Want to connect?</h3>
        <p className="mt-8 text-lg lg:text-xl font-light">
          Please provide details into your project, thank you.
        </p>

        <form className="px-5 lg:px-20" action="https://formspree.io/f/xzborwao" method="post">
          <div className="md:flex items-center mt-12">
            <div className="w-full md:w-1/2 flex flex-col">
              <label className="font-semibold leading-none" htmlFor="name">Name</label>
              <input type="text" name="Name" className="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200" />
            </div>
            <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
              <label className="font-semibold leading-none" htmlFor="email">Email</label>
              <input type="email" name="Email" className="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200" />
            </div>
          </div>
          <div className="md:flex items-center mt-8">
            <div className="w-full flex flex-col">
              <label className="font-semibold leading-none" htmlFor="subject">Subject</label>
              <input type="text" name="Subject" className="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200" />
            </div>

          </div>
          <div>
            <div className="w-full flex flex-col mt-8">
              <label className="font-semibold leading-none" htmlFor="message">Message</label>
              <textarea type="text" name="Message" className="h-40 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-blue-700 mt-4 bg-gray-100 border rounded border-gray-200"></textarea>
            </div>
          </div>
          <div className="flex items-center justify-center w-full pt-10">
            <button type="submit" className="bg-black hover:bg-primary-darker rounded text-white py-5 px-16 text-lg">Get in touch</button>

          </div>
        </form>
      </section>



    </Layout>
  )

};



//highlight-start
export const pageQuery = graphql`
{
  allWpPage(filter: {isFrontPage: {eq: true}}) {
    nodes {
      seo {
        title
        opengraphTitle
        metaDesc
        twitterDescription
        twitterTitle
      }
      id
      title
      content
      homePage {
        section1Text
        section2Title
        section2Text
        section1Title
        section3Text
        section3Title
      }
    }
  }
  allWpPost(sort: {fields: [date], order: DESC}, limit: 6) {
    nodes {
      id
      title
      slug
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
        }
      }
    }
  }
  allWpClient(sort: {fields: [date]}, limit: 30) {
    nodes {
      id
      title
      content
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, width: 400)
            }
          }
        }
      }
    }
  }
  allWpSkill(sort: {fields: [title]}) {
    nodes {
      title
      content
      uri
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, height: 80)
            }
          }
        }
      }
    }
  }
}

`
//highlight-end
